import React from "react";
import { graphql } from "gatsby";
import Layout from "components/Layout";
import { ProductsGrid } from "components";

const StorePage = ({ data }) => {
  console.log(data.allShopifyProduct.edges);

  return (
    <Layout
      pageTitle="Store - Reprocess & Repurpose"
      theme="light"
      footer="relative lg:fixed"
    >
      <ProductsGrid products={data.allShopifyProduct.edges} />
    </Layout>
  );
};

export const query = graphql`
  query StorePageQuery {
    allShopifyProduct {
      edges {
        node {
          title
          shopifyId
          id
          handle
          images {
            gatsbyImageData
          }
          variants {
            price
            productId
            id
            title
            sku
            shopifyId
          }
        }
      }
    }
  }
`;

export default StorePage;
